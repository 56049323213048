
import appData from './appData';
import app from './app';
import wsCall from './wsCall';
import Dialog from './dialog';

function PhoneList() {
}

export default PhoneList;

PhoneList.prototype.init = function() {
  var self = this;

  $(document).on('click', '.phoneListEdit', function() {
    var phoneId = $(this).attr('phoneId');

    app.phoneUpdate.prepareForId(phoneId, function() {
      app.changePage('phoneUpdate');
    });
  });
  $(document).on('click', '.phoneListNew', function() {
    app.phoneUpdate.prepareForNew(function() {
      app.changePage('phoneUpdate');
    });
  });
  $(document).on('click', '.phoneListDelete', function() {
    var phoneId = $(this).attr('phoneId');
    var defer = Dialog.showDelete({
      textWarning: 'All reminders assigned to this phone will also be deleted. Click delete to continue.'
    });

    $.when(defer).done(function() {
      var json = {
        phoneId: phoneId
      };

      appData.vm.pageloaderText = 'Deleting phone';
      appData.vm.pageloaderVisible = true;

      var deferWS = wsCall({
        url: "/phone/delete",
        data: json
      });

      $.when(deferWS).done(function(data, textStatus, jqXHR) {
        self.getList(function() {
          appData.vm.pageloaderVisible = false;
        });
      }).catch(function() {
        appData.vm.pageloaderVisible = false;
      });
    });
  });
};

PhoneList.prototype.getList = function(cb) {

  var deferWS = wsCall({
    url: "/phone/list",
    data: {}
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    appData.vm.phoneList = data.data.map(function(x) {
      if (x.lookupCarrier) {
        x.lookupCarrier = x.lookupCarrier.replace(/^\s+|\s+$/g, '');
      }
      return x;
    });
    if (cb) {
      cb();
    }
  });
};
