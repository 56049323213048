
import appData from './appData';
import app from './app';
import wsCall from './wsCall';
import dayjs from 'dayjs';
var moment = require('moment-timezone');

function ReminderUpdate() {
}

export default ReminderUpdate;

ReminderUpdate.prototype.init = function(tourame) {
  var self = this;

  $(document).on('click', '.reminderSubmit', function(e) {
    e.preventDefault();
    $('.reminderUpdateSubmit').click();
  });

  $('.reminderUpdateForm').on('submit', function(e) {
    e.preventDefault();

    self.submit();
  });

};

ReminderUpdate.prototype.submit = function() {
  var self = this;

  $('.reminderSubmit').addClass('is-loading');

  // One format accepted by dayjs is 2019-02-02T08:00
  var dt = dayjs(appData.vm.reminderUpdateDate + 'T' + appData.vm.reminderUpdateTime);

  var json = {
    phoneId: appData.vm.reminderUpdatePhoneId,
    unixUTC: dt.unix(),
    txt: appData.vm.reminderUpdateText,
    occurrence: appData.vm.reminderUpdateOccurrence,
    holiday: appData.vm.reminderUpdateIsHoliday ? appData.vm.reminderUpdateHoliday : null,
    appointment: appData.vm.reminderUpdateAppointment,
    notifyBefore: appData.vm.reminderUpdateNotifyBefore,
    method: appData.vm.reminderUpdateMethod,
    tzClient: appData.vm.reminderUpdateTzClient
  };

  if (appData.vm.reminderUpdateId !== 'new') {
    json.reminderId = appData.vm.reminderUpdateId;
  }

  var deferWS = wsCall({
    url: "/reminder/update",
    data: json
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    app.reminderList.getList(function() {
      app.changePage('reminderList');
    });
  });
};

ReminderUpdate.prototype.load = function(cb) {
  if (appData.vm.reminderUpdateId === 'new') {
    this.prepareForNew(cb);
  }
  else if (appData.vm.reminderUpdateId) {
    this.prepareForId(appData.vm.reminderUpdateId, cb);
  }
  else {
    cb('reminderList');
  }
};

ReminderUpdate.prototype.prepareForNew = function(cb) {

  var dt = dayjs();

  dt = dt.add(1, 'day').set('hour', 8).set('minute', 0);

  appData.vm.reminderUpdateId = 'new';
  appData.vm.reminderUpdatePhoneId = appData.vm.saved.primaryPhoneId;
  appData.vm.reminderUpdateDate = dt.format('YYYY-MM-DD');
  appData.vm.reminderUpdateTime = dt.format('HH:mm');
  appData.vm.reminderUpdateText = null;
  appData.vm.reminderUpdateOccurrence = "once";
  appData.vm.reminderUpdateHoliday = 'christmas';
  appData.vm.reminderUpdateIsHoliday = false;
  appData.vm.reminderUpdateAppointment = false;
  appData.vm.reminderUpdateNotifyBefore = 60;
  appData.vm.reminderUpdateMethod = "phone";
  appData.vm.reminderUpdateTzClient = moment.tz.guess();
  appData.vm.saved.reminderAdvancedOpen = false;
  appData.vm.save();

  var deferWS2 = wsCall({
    url: "/phone/list",
    data: {}
  });

  $.when(deferWS2).done(function(data, textStatus, jqXHR) {
    appData.vm.phoneList = data.data;
    cb();
  });
};

ReminderUpdate.prototype.prepareForId = function(reminderId, cb) {
  appData.vm.reminderUpdateId = reminderId;

  var deferWS = wsCall({
    url: "/reminder/get",
    data: {
      reminderId: reminderId
    }
  });
  var deferWS2 = wsCall({
    url: "/phone/list",
    data: {}
  });

  $.when(deferWS2).done(function(data, textStatus, jqXHR) {
    appData.vm.phoneList = data.data;
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    var dt = dayjs.unix(data.data.unixUTC);

    appData.vm.reminderUpdateId = data.data.id;
    appData.vm.reminderUpdatePhoneId = data.data.phoneId;
    appData.vm.reminderUpdateDate = dt.format('YYYY-MM-DD');
    appData.vm.reminderUpdateTime = dt.format('HH:mm');
    appData.vm.reminderUpdateText = data.data.txt;
    appData.vm.reminderUpdateOccurrence = data.data.occurrence;
    appData.vm.reminderUpdateHoliday = data.data.holiday ? data.data.holiday : 'christmas';
    appData.vm.reminderUpdateIsHoliday = data.data.holiday ? true : false;
    appData.vm.reminderUpdateAppointment = data.data.appointment;
    appData.vm.reminderUpdateNotifyBefore = data.data.notifyBefore;
    appData.vm.reminderUpdateMethod = data.data.method;
    appData.vm.reminderUpdateTzClient = data.data.tzClient;
    if ((appData.vm.reminderUpdateOccurrence !== 'once') || (appData.vm.reminderUpdatePhoneId !== appData.vm.saved.primaryPhoneId)) {
      appData.vm.saved.reminderAdvancedOpen = true;
    }

    Vue.nextTick(function() {
      app.updateCalendars();
    });
  });

  $.when(deferWS, deferWS2).done(function() {
    cb();
  })
};
