var jquery = require("/js/lib/jquery");
window.$ = window.jQuery = jquery;

import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import 'babel-polyfill';
import Dialog from './dialog';
import ServiceBotHandlerApp from './serviceBotHandlerApp';

dayjs.extend(LocalizedFormat);



import cookie from './cookie';
import config from './clientServerConfig.json';
import wsCall, { wsCallAsync } from './wsCall';
import Tours from './tours';
import PhoneUpdate from './phoneUpdate';
import PhoneList from './phoneList';
import ReminderUpdate from './reminderUpdate';
import ReminderList from './reminderList';
import appData from './appData';

function App() {
  var self = this;
  this.tours = new Tours();
  this.phoneUpdate = new PhoneUpdate();
  this.phoneList = new PhoneList();
  this.reminderUpdate = new ReminderUpdate();
  this.reminderList = new ReminderList();
}

function isMobile() {
 return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? true : false;
}

function activePageHandler(val) {
  document.documentElement.classList.remove(
    'htmlColorBlue',
    'htmlColorWhite'
  );
  $('#accountSection').removeClass('is-active');
  $('.pageLink').removeClass('is-active');
  $('.navbar-burger').removeClass('is-active');
  $('.navbar-menu').removeClass('is-active');

  var htmlClass = app.pages[val].htmlClass;

  if (htmlClass) {
    document.documentElement.classList.add(htmlClass);
  }

  $('.page-' + val).addClass('is-active');

  switch (val) {
    case 'account':
      $('#accountSection').addClass('is-active');
      break;
  }
}

App.prototype.changePage = function(newPage, options) {
  var self = this;
  // There are at least two possible states depending on how this is called:
  // newPage = currentURL != activePage variable
  // newPage != currentURL = activePage variable
  // I this assumption is true: either way activePage is always the old page
  var parts = window.location.pathname.replace(/\/$/, "").split('/');
  var currentURL = parts[parts.length - 1];
  var activePage = appData.vm.activePage;
 
  // Remove # anchor ending of URL
  currentURL = currentURL.replace(/#+$/, '');
  newPage = newPage.replace(/#+$/, '');

  var oldPage = activePage;

  if (!options) {
    options = {};
  }

  if (options.forcePageChange || (oldPage !== newPage)) {
    // Login is special case with no path
    var historyPage = newPage;

    if (options.skipHistory) {
      history.replaceState(null, null, '/app/' + historyPage);
    }
    else {
      history.pushState(null, null, '/app/' + historyPage);
    }

    if (newPage === 'login') {
      appData.vm.activePage = newPage;
    }
    else {
      self.onBeforePageActivated(newPage, function(newPageOverride) {
        appData.vm.activePage = newPageOverride;
      });
    }
  }
};

App.prototype.onBeforePageActivated = function(newPage, activatePage) {
  var self = this;

  
  if (!appData.vm.saved.privileges.account.active && (newPage !== 'account')) {
    history.pushState(null, null, '/app/account');
    activatePage('account');
  }
  else if (!appData.vm.saved.primaryPhoneVerified && (newPage !== 'account')) {
    self.phoneUpdate.prepareForId(appData.vm.saved.primaryPhoneId, function() {
      history.pushState(null, null, '/app/phoneUpdate');
      activatePage('phoneUpdate');
      Vue.nextTick(function() {
        self.tours.start('noPhone');
      });
    });
  }
  else if (!appData.vm.phoneUpdateId && (newPage === 'phoneUpdate')) {
    window.location = '/app/phoneList';
  }  
  else if (!appData.vm.reminderUpdateId && (newPage === 'reminderUpdate')) {
    window.location = '/app/reminderList';
  }  
  else {
    switch (newPage) {
      case 'phoneList':
        self.phoneList.getList(function() {
           activatePage(newPage);
        });
        break;
      case 'reminderList':
        self.reminderList.getList(function() {
           activatePage(newPage);
        });
        break;
      case 'reminderUpdate':
        self.reminderUpdate.load(function(changePage) {
          if (changePage) {
            activatePage(changePage);
          }
          else {
            activatePage(newPage);
          }
        });
        break;
      default:
        activatePage(newPage);
        break;
    }
  }
};

App.prototype.init = async function() {
  var self = this;

  this.pages = {
    account: {
      showNavbar: true,
      htmlClass: 'htmlColorWhite'
    },
    login: {
      showNavbar: false,
      htmlClass: 'htmlColorWhite'
    },
    reminderList: {
      showNavbar: true,
      htmlClass: 'htmlColorBlue'
    },
    reminderUpdate: {
      showNavbar: true,
      htmlClass: 'htmlColorBlue'
    },
    phoneList: {
      showNavbar: true,
      htmlClass: 'htmlColorBlue'
    },
    phoneUpdate: {
      showNavbar: true,
      htmlClass: 'htmlColorBlue'
    }
  };

  appData.init({
    watch: {
      activePage: activePageHandler,
      reminderUpdateIsHoliday: function() {
        if (appData.vm.reminderUpdateIsHoliday) {
          $('.reminder-time').attr("disabled", "disabled");
          $('.reminder-date').attr("disabled", "disabled");
        }
        else {
          $('.reminder-time').removeAttr('disabled');
          $('.reminder-date').removeAttr('disabled');
        }
      }
    },
    computed: {
      showNavbar: function() {
        return self.pages[this.activePage].showNavbar;
      },
    },
    methods: {
      getReminderDescription: ReminderList.getReminderDescription,
      getReminderTextFormatted: ReminderList.getReminderTextFormatted
    },
    mounted: function() {}
  });

  self.setMediaQueryData();
  window.addEventListener("resize", function() { self.setMediaQueryData() });

  var jwtAccess = cookie.get('jwtAccess');

  if (jwtAccess) {
    try {
      let result = await wsCallAsync({
        url: "/account/info",
        data: {}
      });

      appData.vm.serviceBotHmac = result.data.data.serviceBotHmac;

      appData.vm.saved.primaryPhoneVerified = result.data.data.primaryPhoneVerified;
      appData.vm.saved.primaryPhoneId = result.data.data.primaryPhoneId;
      appData.vm.saved.phoneLimit = result.data.data.phoneLimit;
      appData.vm.saved.email = result.data.data.userInfo.email;
      appData.vm.saved.privileges = result.data.data.privileges;
      appData.vm.save();

      self.initServiceBot();

      function foneselfFreshChatInitializedSetProperties() {
        // To set unique user id in your system when it is available
        window.fcWidget.setExternalId(result.data.data.userInfo.email);

        // To set user name
        window.fcWidget.user.setFirstName(result.data.data.userInfo.firstName);

        // To set user email
        window.fcWidget.user.setEmail(result.data.data.userInfo.email);

        // To set user properties
        window.fcWidget.user.setProperties({
          primaryPhoneVerified: result.data.data.primaryPhoneVerified,
          phoneLimit: result.data.data.phoneLimit,
          accountId: result.data.data.userInfo.accountId,
          primaryPhoneId: result.data.data.primaryPhoneId,
          accountType: result.data.data.privileges.account.type
        });   
      }

      if (window.foneselfFreshChatInitializedDone) {
        foneselfFreshChatInitializedSetProperties();
      }
      else {
        document.addEventListener("foneselfFreshChatInitialized", function(event) {
          foneselfFreshChatInitializedSetProperties();
        });
      }

      var foundPage = false;
      var parts = window.location.pathname.replace(/\/$/, "").split('/');
      if (parts.length > 0) {
        var page = parts[parts.length - 1];

        if (app.pages.hasOwnProperty(page)) {
          self.changePage(page, { forcePageChange: true });
          foundPage = true;
        }
      }
      if (!foundPage) {
        self.changePage('reminderList', { forcePageChange: true });
      }

      jwtAccess = JSON.parse(jwtAccess);
    }
    catch (result) {
      console.error('Error getting account info: ' + result); 
      window.location = '/';
    }    

  }
  else {
    window.location = '/';
  }

  // handler is not intially called
  activePageHandler(appData.vm.activePage);

  $('.buttonSignout').on('click', function() {
    cookie.remove('jwtAccess');
    cookie.remove('jwtRefresh');
    window.location = '/login';
  });

  this.addBurgerHandler();

  $('.buttonAccount').on('click', function() {
    self.changePage('account');
  });
  $('.buttonReminderList').on('click', function() {
    self.changePage('reminderList');
  });
  $('.buttonReminderUpdate').on('click', function() {
    self.reminderUpdate.prepareForNew(function() {
      self.changePage('reminderUpdate');
      /*if (!appData.vm.saved.tours.reminderUpdatexxxxx) {
        setTimeout(function() {
          app.tours.start('reminderUpdate');
          appData.vm.saved.tours.reminderUpdate = true;
          appData.vm.save();
        }, 1);
      }*/
    });
  });
  $('.buttonPhoneList').on('click', function() {
    self.changePage('phoneList');
  });

  flatpickr(".flatpickrDate", {
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d"
  });
  flatpickr(".flatpickrTime", {
    enableTime: true,
    noCalendar: true,
    altInput: true,
    altFormat: "h:i K",
    dateFormat: "H:i",
  });

  $('.buttonPhones').on('click', function() {
    $(this).parent().toggleClass('is-active');
  });

  //this.tours.init();

  this.phoneUpdate.init();
  this.phoneList.init();
  this.reminderUpdate.init();
  this.reminderList.init();

  self.enableUrlListener();

  if (appData.vm.saved.privileges.emailConfirmExpired) {
    appData.vm.dialog.info.title = 'Email Confirmation Required';
    appData.vm.dialog.info.message = 'Your email needs to be confirmed before you can continue. Please check your email and click the link to confirm your email.';
    appData.vm.dialog.info.button2 = 'Resend Email';
    var defer = Dialog.showInfo();

    $.when(defer).done(function() {
      location.reload();
    });
    $(document).on('click', '.dialogButton2', async function(e) {
      e.preventDefault();
      $('.dialogButton2').addClass('is-loading');

      try {
        let result = await wsCallAsync({
          url: "/account/resendConfirmation",
          data: {}
        });
        appData.vm.dialog.info.show = false;
        Vue.nextTick(function() {
          appData.vm.dialog.info.button2 = null;
          appData.vm.dialog.info.title = 'Email Confirmation Sent';
          appData.vm.dialog.info.message = 'The confirmation email has been resent. Please check your email and click the link to confirm your email.';
          var defer2 = Dialog.showInfo();
          $.when(defer2).done(function() {
            location.reload();
          });
        });

      }
      catch (result) {
      }    
    });

  }

  $('.reminderListSection').on('click', function() {
    var el = $(this);
    var sectionId = el.attr('sectionId');

    appData.vm.saved.reminderListOpen[sectionId] = !appData.vm.saved.reminderListOpen[sectionId];
    appData.vm.save();
  });
};

App.prototype.updateCalendars = function() {

  $('.flatpickrDate').each(function() {
    var x = $(this)[0]._flatpickr;

    if (x) {
      x.setDate($(this).val(), true);
    }
  });
  $('.flatpickrTime').each(function() {
    var x = $(this)[0]._flatpickr;

    if (x) {
      x.setDate($(this).val(), true);
    }
  });

};

App.prototype.enableUrlListener = function() {
  var self = this;

  window.addEventListener('popstate', function(e) {
    var parts = window.location.pathname.replace(/\/$/, "").split('/');
    if (parts.length > 0) {
      var page = parts[parts.length - 1];

      if (app.pages.hasOwnProperty(page)) {
        self.changePage(page, {
          skipHistory: true/*,
          forcePageChange: true*/
        });
      }
    }
  });
};

App.prototype.setMediaQueryData = function() {
  appData.vm.screenSM = window.matchMedia("(min-width: 576px)").matches;
  appData.vm.screenMD = window.matchMedia("(min-width: 768px)").matches;
  appData.vm.screenLG = window.matchMedia("(min-width: 992px)").matches;
  appData.vm.screenXL = window.matchMedia("(min-width: 1200px)").matches;
};

App.prototype.initServiceBot = function() {
	window.servicebotSettings = {
    "servicebot_id": config.env[process.env.APPENV].servicebotId,
    "service": "Foneself",
    "options": {
        "forceCard": true,
        "card": {
            "hideAddress": true
        },
        "messageOnCancel": "We're sad to see you go",
        "i18n": {
            "resources": {}
        },
        "cancel_now": true,
        "behavior": {
            "signup": {
                "termsAndConditions": {
                    "required": true,
                    "link": "https://www.foneself.com/terms.html",
                    "errorMessage": "You must accept the Terms and Conditions",
                    "text": "Accept Terms and Conditions"
                }
            },
            "pricing": {
                "showPriceAsMonth": true,
                "showPriceAsMonthDiscountBadge": true
            }        
          }
    },
    "email": appData.vm.saved.email,
    "hash": appData.vm.serviceBotHmac,
    "type": "portal",
    "integration": "html",
    "handleResponse": ServiceBotHandlerApp.handler
  };
	(function () { var s = document.createElement('script'); s.src = 'https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js'; s.async = true; s.type = 'text/javascript'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();
};

App.prototype.addBurgerHandler = function() {
  $(document).ready(function() {

    // Check for click events on the navbar burger icon
    $(".navbar-burger").click(function() {

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $(".navbar-burger").toggleClass("is-active");
      $(".navbar-menu").toggleClass("is-active");

    });
  });
};

var app = new App();

app.init();


export default app;
