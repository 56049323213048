
import wsCall, { wsCallAsync } from './wsCall';

var ServiceBotHandlerApp    = {};
export default ServiceBotHandlerApp;

ServiceBotHandlerApp.handler = async function(payload) {
  if (payload.event == 'create_subscription') {
    return await ServiceBotHandlerApp.createSubscription(payload);
  }
  if (payload.event == 'change_plan') {
    return await ServiceBotHandlerApp.accountUpdateLimits();
  }
  if (payload.event == 'resubscribe') {
    return await ServiceBotHandlerApp.accountUpdateLimits();
  }
  if (payload.event == 'cancel_subscription') {
    return await ServiceBotHandlerApp.accountUpdateLimits();
  }
};

ServiceBotHandlerApp.createSubscription = async function(payload) {
  return new Promise(async function(resolve, reject) {
    try {
      let result = await wsCallAsync({ 
      url: "/account/create",
      data: {
        payload: payload
      }});

      if (result.error) {
        reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
      }
      else {
        resolve();
        location.reload();
      }
    }
    catch (err) {
      reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
    }
  });
};

ServiceBotHandlerApp.accountUpdateLimits = async function() {
  return new Promise(async function(resolve, reject) {
    try {
      let result = await wsCallAsync({ 
      url: "/account/updateLimits",
      data: {
      }});

      if (result.error) {
        reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
      }
      else {
        resolve();
        location.reload();
      }
    }
    catch (err) {
      reject({
          message: 'An error occurred.',
          callback: function() {
            // call back to redirect or other actions you want to take.
            //window.location = '/';
          }
        });
    }
  });
};
