
import appData from './appData';
import app from './app';
import wsCall from './wsCall';
import Dialog from './dialog';
import dayjs from 'dayjs';

ReminderList.ocurrenceDescriptions = {
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
  daily: "Daily"
};

ReminderList.holidays = {
  christmas: "Christmas",
  independenceday: "Independence Day",
  laborday: "Labor Day",
  memorialday: "Memorial Day",
  thanksgiving: "Thanksgiving"
};

ReminderList.notifyBefore = {
  min5: "5 minutes before",
  min10: "10 minutes before",
  min15: "15 minutes before",
  min20: "20 minutes before",
  min30: "30 minutes before",
  min45: "45 minutes before",
  min60: "1 hour before",
  min120: "2 hours before",
  min240: "4 hours before",
  min480: "8 hours before",
  min1440: "1 day before",
  min2880: "2 days before",
  min5760: "4 days before",
  min10080: "1 week before",
  min20160: "2 weeks before"
};

function ReminderList() {
}

export default ReminderList;

ReminderList.prototype.init = function() {
  var self = this;

  $(document).on('click', '.reminderListEdit', function() {
    var reminderId = $(this).attr('reminderId');

    app.reminderUpdate.prepareForId(reminderId, function() {
      app.changePage('reminderUpdate');
    });
  });
  $(document).on('click', '.reminderListDelete', function() {
    var reminderId = $(this).attr('reminderId');
    var defer = Dialog.showDelete();


    $.when(defer).done(function() {
      var json = {
        reminderId: reminderId
      };

      appData.vm.pageloaderText = 'Deleting reminder';
      appData.vm.pageloaderVisible = true;

      var deferWS = wsCall({
        url: "/reminder/delete",
        data: json
      });

      $.when(deferWS).done(function(data, textStatus, jqXHR) {
        self.getList(function() {
          appData.vm.pageloaderVisible = false;
        });
      });
    });
  });
};

ReminderList.prototype.getList = function(cb) {
  var self = this;

  var deferWS = wsCall({
    url: "/reminder/list",
    data: {}
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    var nowUnixUTC = dayjs().unix();

    let listItem = data.data.filter(function (x) {
      return (x.occurrence === 'once') && !x.holiday && (x.unixUTC >= nowUnixUTC);
    });
    listItem.sort(function(x1, x2) {
      return x1.unixUTC - x2.unixUTC;
    });
    appData.vm.reminderListOnce = listItem;

    listItem = data.data.filter(function (x) {
      return x.holiday;
    });
    listItem.sort(function(x1, x2) {
      return x1.unixUTC - x2.unixUTC;
    });
    appData.vm.reminderListHoliday = listItem;

    listItem = data.data.filter(function (x) {
      return (x.occurrence !== 'once') && !x.holiday;
    });
    listItem.sort(function(x1, x2) {
      return x1.unixUTC - x2.unixUTC;
    });
    appData.vm.reminderListRecurring = listItem;

    listItem = data.data.filter(function (x) {
      return (x.occurrence === 'once') && !x.holiday && (x.unixUTC < nowUnixUTC);
    });
    listItem.sort(function(x1, x2) {
      return x1.unixUTC - x2.unixUTC;
    });
    appData.vm.reminderListHistory = listItem;

    if (cb) {
      if (!appData.vm.saved.tours.reminderList) {
        Vue.nextTick(function() {
          app.tours.start('reminderList');
          appData.vm.saved.tours.reminderList = true;
          appData.vm.save();
        });
      }
      cb();
    }
  });
};

ReminderList.getReminderDescription = function(item) {
  var dt = dayjs.unix(item.unixUTC);
  var dtFormatted = dt.format('LLL');
  var description = dtFormatted;

  if ((item.occurrence !== 'once') && !item.holiday) {
    description += ', ' + ReminderList.ocurrenceDescriptions[item.occurrence];
  }
  else if (item.holiday) {
    description += ', ' + ReminderList.holidays[item.holiday];
  }

  description += ', Notify ';

  var keyNotifyBefore = 'min' + item.notifyBefore;
  var textNotifyBefore = item.notifyBefore + ' minutes before';
  if (ReminderList.notifyBefore.hasOwnProperty(keyNotifyBefore)) {
    textNotifyBefore = ReminderList.notifyBefore[keyNotifyBefore]
  }
  description += textNotifyBefore;
  description += (item.method==='text') ? ' by text' : ' by phone';

  return description;
};

ReminderList.getReminderTextFormatted = function(item) {
  var text = item.txt.substring(0, 60);

  if (item.txt.length > text.length) {
    text += '...';
  }

  return text;
}
