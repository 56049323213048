
import appData from './appData';
import app from './app';
import wsCall, { wsCallAsync } from './wsCall';

function PhoneUpdate() {
}

export default PhoneUpdate;

PhoneUpdate.prototype.init = function(tourame) {
  var self = this;

  if (!appData.vm.phoneUpdateId) {
    
  }

  $('.phoneUpdateNext').on('click', function() {
    switch (appData.vm.phoneUpdateStep) {
      case 1:
        $('.phoneUpdatePhoneSubmitInput').click();
        break;
      case 2:
        self.submitCode();
        break;
    }
  });
  $('.phoneUpdatePrevious').on('click', function() {
    if (appData.vm.phoneUpdateStep !== 1) {
      appData.vm.phoneUpdateStep -= 1;
    }
  });

  $('.phoneUpdatePhoneForm').on('submit', function(e) {
    e.preventDefault();

    self.submitPhone();
  });
};

PhoneUpdate.prototype.submitPhone = function() {
  var self = this;

  $('.phoneUpdateNext').addClass('is-loading');

  var json = {
      phoneName: appData.vm.phoneUpdatePhoneName,
      phone: appData.vm.phoneUpdatePhone
    };

  if (appData.vm.phoneUpdateId) {
    json.phoneId = appData.vm.phoneUpdateId;
  }

  var deferWS = wsCall({
    url: "/phone/update",
    data: json
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    appData.vm.phoneUpdateId = data.phoneId;
    //appData.vm.phoneUpdateStep += 1;
    self.requestCode();
  });
};

PhoneUpdate.prototype.requestCode = function() {
  var self = this;

  $('.phoneUpdateNext').addClass('is-loading');

  var deferWS = wsCall({
    url: "/phone/requestCode",
    data: {
      phoneId: appData.vm.phoneUpdateId
    }
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    appData.vm.phoneUpdateCode = null;
    appData.vm.phoneUpdateStep += 1;
  });
};

PhoneUpdate.prototype.submitCode = function() {
  var self = this;

  $('.phoneUpdateNext').addClass('is-loading');

  var deferWS = wsCall({
    url: "/phone/verify",
    data: {
      phoneId: appData.vm.phoneUpdateId,
      code: appData.vm.phoneUpdateCode
    }
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    appData.vm.saved.primaryPhoneVerified = true;
    appData.vm.save();
    app.changePage('reminderList');
    appData.vm.phoneUpdateStep = 1;
  });
};

PhoneUpdate.prototype.prepareForNew = function(cb) {

  appData.vm.phoneUpdatePhone = null;
  appData.vm.phoneUpdateType = null;
  appData.vm.phoneUpdateCarrier = null;
  appData.vm.phoneUpdatePhoneName = null;
  appData.vm.phoneUpdateVerified = false;

  cb();

};

PhoneUpdate.prototype.prepareForId = function(phoneId, cb) {
  appData.vm.phoneUpdateId = phoneId;

  var deferWS = wsCall({
    url: "/phone/get",
    data: {
      phoneId: phoneId
    }
  });

  $.when(deferWS).done(function(data, textStatus, jqXHR) {
    appData.vm.phoneUpdatePhone = data.data.lookupFormatted;
    appData.vm.phoneUpdateType = data.data.lookupType;
    appData.vm.phoneUpdateCarrier = data.data.lookupCarrier;
    appData.vm.phoneUpdatePhoneName = data.data.phoneName;
    appData.vm.phoneUpdateVerified = data.data.verified;
    cb();
  });
};
